<template>
<div class="mb-4">
      <Subtitle title="Nyheter"></Subtitle>
      <Loader v-if="loading" cols="3" rows="3"></Loader>
      <div v-else>
            <div v-for="entry in news" :key="entry.id" class="d-flex flex-column">
                  <div>
                        <h4>{{entry.title}}</h4>
                  </div>
                  <div class="my-3">
                        <span v-html="text(entry.message)"></span>
                  </div>
                  <div>
                      <span class="font-italic">{{date(entry.date)}}</span>                      
                  </div>                                    
                  <div>
                        <hr/>
                  </div>
            </div>            
      </div>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const Subtitle = () => import("@/components/common/Subtitle");

export default {
      name: 'DashboardPayment',
      components: {
            Alert,
            Loader,
            Subtitle,
      },
      data() {
            return {};
      },
      computed: {
            news() {
                  var data = this.$store.getters["dashboard/dashboard"];
                  if (data && data.news)
                        return data.news;
                  return [];
            },
            loading() {
                  return this.$store.getters["dahsboard/loading"];
            },            
      },
      methods: {
            date(date) {
                  return this.$date.stringToReadableFormatWithTime(date);
            },
            text(text)
            {
                  return text.replace("\r\n", "<br>");
            }
      }
};
</script>
